<template>
  <modal
    id="modalSuprimentoProduto"
    :titulo="titulo"
    :exibir="exibir"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <form-produto :form="dados" @refs="getRefs" />
    <vincular-fornecedores
      @repassandoIdsFornecedor="repassandoIdsFornecedor"
      :fornecedoresExistentes="dados.fornecedores"
      :possuiId="this.dados.id"
    />
    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import ProdutosService from '@/common/services/produtos/produtos.service.js';

// Components:
import FormProduto from './Form';
import VincularFornecedores from './fornecedores/Index';
import Modal from '@/components/modal/Modal';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    dados: { type: Object, default: Object },
  },
  components: {
    Modal,
    FormProduto,
    VincularFornecedores,
  },
  data() {
    return {
      refs: [],
    };
  },
  computed: {
    titulo() {
      return !this.dados.id
        ? this.$t('GERAL.CADASTRAR')
        : this.$t('GERAL.EDITAR');
    },
  },
  methods: {
    repassandoIdsFornecedor(fornecedoresId) {
      this.dados.fornecedoresId = fornecedoresId;
    },
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES CRUD:
    salvar() {
      if (!this.validarFormulario()) return;
      !this.dados.id ? this.cadastrar() : this.editar();
    },
    cadastrar() {
      this.$store.dispatch(START_LOADING);
      ProdutosService.criar(this.dados)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    editar() {
      this.$store.dispatch(START_LOADING);
      ProdutosService.editar(this.dados)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'produto');
    },
  },
};
</script>
