<template>
  <b-table
    :items="items"
    :fields="fields"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item.id)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="confirmarExclusao(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ProdutosService from '@/common/services/produtos/produtos.service.js';
import helpers from '@/common/utils/helpers';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'codigo',
          label: 'Código',
          sortable: true,
        },
        {
          key: 'dataCriacaoProduto',
          label: 'Data Cadastro',
          sortable: true,
          formatter: (value) => {
            return helpers.formatarDataBr(value);
          },
        },
        {
          key: 'descricao',
          label: 'Descrição',
          sortable: true,
        },
        {
          key: 'unidadeMedida',
          label: 'Unidade de medida',
          sortable: true,
        },
        {
          key: 'valor',
          label: 'Valor',
          sortable: true,
        },
        {
          key: 'quantidadeMinima',
          label: 'Quantidade mínima',
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    editar(item) {
      this.$store.dispatch(START_LOADING);
      ProdutosService.obterProduto(item)
        .then(({ data }) => {
          this.$emit('editar', 'produto', data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: 'produto',
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item) : {};
    },
    excluir(item) {
      this.$store.dispatch(START_LOADING);
      ProdutosService.excluir(item.id)
        .then(() => {
          mensagem.sucesso(this.$t('GERAL.SUCESSO'));
          this.$emit('atualiza-tabela');
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
          this.fecharModal();
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'produto');
    },
  },
};
</script>
