<template>
  <b-row>
    <b-col md="4">
      <input-text
        ref="descricao"
        v-model="filtro.descricao"
        :label="$t('GERAL.DESCRICAO')"
        :placeholder="$t('GERAL.DESCRICAO')"
        @enter="filtrar"
      />
    </b-col>
    <b-col cols="12" md="auto">
      <b-button
        class="w-100 botao-acao-filtro mt-3"
        variant="primary"
        @click="filtrar"
      >
        {{ $t('GERAL.PESQUISAR') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
// Components:
import { InputText } from '@/components/inputs';

export default {
  components: {
    InputText,
  },
  data() {
    return {
      filtro: {
        descricao: '',
      },
    };
  },
  watch: {
    filtro: {
      handler(value) {
        this.$emit('filtro', value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // FUNÇÕES CRUD:
    filtrar() {
      this.$emit('filtrar');
    },
    limparFiltro() {
      Object.keys(this.filtro).forEach((item) => {
        this.filtro[item] = '';
      });
      this.$emit('limparFiltro');
    },
  },
};
</script>
