<template>
  <div class="row">
    <div class="col-12">
      <h4>{{ $t('SUPRIMENTOS.DADOS_PRODUTO') }}</h4>
    </div>
    <!-- data de cadastro e nome -->
    <div class="col-12 col-md-4">
      <input-date-only-btn
        ref="dataCriacaoProduto"
        v-model="dataRetorno"
        :label="$t('SUPRIMENTOS.DATA_CADASTRO')"
        required
      />
    </div>
    <div class="col-12 col-md-8">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        :label="$t('SUPRIMENTOS.NOME')"
        :placeholder="$t('SUPRIMENTOS.NOME')"
        :maxLength="155"
        required
      />
    </div>

    <!-- unidade de medida e quantidade -->
    <div class="col-12 col-md-6">
      <input-select-search
        ref="unidadeMedida"
        v-model="form.unidadeMedida"
        :label="$t(`SUPRIMENTOS.UNIDADE_MEDIDA`)"
        :options="opcoes.unidadesMedida"
        required
      />
    </div>
    <div class="col-12 col-md-6">
      <input-number
        ref="quantidadeMinima"
        v-model="form.quantidadeMinima"
        :label="$t('SUPRIMENTOS.QUANTIDADE_MINIMA')"
        :placeholder="$t('GERAL.SOMENTE_NUMEROS')"
        required
      />
    </div>

    <div class="col-12 col-md-6">
      <input-number
        ref="valor"
        v-model="form.valor"
        :label="'Valor'"
        :placeholder="$t('GERAL.SOMENTE_NUMEROS')"
        decimal
        required
      />
    </div>

    <!-- observações -->
    <div class="col-12">
      <input-text-area
        ref="observacoes"
        v-model="form.observacao"
        :label="$t('SUPRIMENTOS.OBSERVACOES')"
        :placeholder="$t('SUPRIMENTOS.OBSERVACOES')"
        :maxLength="255"
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import {
  InputDateOnlyBtn,
  InputNumber,
  InputSelectSearch,
  InputText,
  InputTextArea,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputNumber,
    InputSelectSearch,
    InputText,
    InputTextArea,
  },
  data() {
    return {
      opcoes: {
        unidadesMedida: [],
      },
      dataRetorno: '',
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.getUnidadesMedida();
    this.dataRetorno = this.form.dataCriacaoProduto;
  },
  watch: {
    dataRetorno(dataRetorno) {
      this.form.dataCriacaoProduto = dataRetorno;
    },
  },
  methods: {
    getUnidadesMedida() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('unidade-medida')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            value: row.id,
            text: row.descricao,
          }));
          this.opcoes.unidadesMedida = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
