<template>
  <div>
    <h4>{{ $t('SUPRIMENTOS.VINCULAR_FORNECEDORES_AO_PEDIDO') }}</h4>
    <todos-fornecedores
      @emitir-selecionado="obterFornecedorId"
      :selecionadoRetornoLista="selecionadoRetornoLista"
      :fornecedoresExistentesIds="fornecedoresExistentesIds"
    />
    <fornecedores-vinculados
      :listaFornecedores="selecionados"
      @remover-fornecedor="remover"
    />
  </div>
</template>
<script>
// Components:
import TodosFornecedores from './TodosFornecedores.vue';
import FornecedoresVinculados from './FornecedoresVinculados.vue';

// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EmpresaService from '@/common/services/empresa/empresa.service.js';
import ProdutoService from '@/common/services/produtos/produtos.service.js';

export default {
  components: {
    TodosFornecedores,
    FornecedoresVinculados,
  },
  props: {
    fornecedoresExistentes: { type: Array, default: Array },
    possuiId: { type: String, default: String },
  },
  data() {
    return {
      selecionados: [],
      selecionadoRetornoLista: {},
      fornecedoresExistentesIds: [],
    };
  },
  mounted() {
    this.selecionados = this.fornecedoresExistentes;
    this.fornecedoresExistentesIds = this.fornecedoresExistentes.map(
      (x) => x.id
    );
  },
  watch: {
    selecionados(item) {
      const ids = item.map((x) => x.id);
      this.$emit('repassandoIdsFornecedor', ids);
    },
  },
  methods: {
    adicionarFornecedor(form) {
      ProdutoService.adicionarFornecedor(form)
        .then(({ data }) => {
          mensagem.sucesso(data.mensagem);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    removerFornecedor(form) {
      ProdutoService.removerFornecedor(form)
        .then(({ data }) => {
          mensagem.sucesso(data.mensagem);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    obterFornecedorId(id) {
      this.$store.dispatch(START_LOADING);
      EmpresaService.obterFornecedorId(id)
        .then(({ data }) => {
          this.selecionados.push(data);

          if (this.possuiId !== '') {
            this.adicionarFornecedor({
              fornecedorId: id,
              produtoId: this.possuiId,
            });
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    remover(item) {
      this.selecionados = this.selecionados.filter((x) => x.id !== item.id);
      this.selecionadoRetornoLista = item;

      if (this.possuiId !== '') {
        this.removerFornecedor({
          fornecedorId: item.id,
          produtoId: this.possuiId,
        });
      }
    },
  },
};
</script>
