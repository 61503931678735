<template>
  <b-row>
    <b-col md="8">
      <InputSelectTyping
        ref="tipoContatoId"
        :options="fornecedoresDados"
        v-model="fornecedorId"
        :label="'Fornecedores'"
        placeholder="Selecione ou digite"
        required
        @pesquisa-nome="getFornecedores"
        :loading="loadingFornecedor"
      />
    </b-col>
    <b-col md="auto">
      <b-button
        class="botao-acao-filtro mb-3 w-100"
        variant="primary"
        @click="selecionarFornecedor"
        >Selecionar</b-button
      >
    </b-col>
  </b-row>
</template>

<script>
// Utils & Aux:
// import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import { InputSelectTyping } from '@/components/inputs';
// Services:
import EmpresaService from '@/common/services/empresa/empresa.service.js';

export default {
  components: {
    InputSelectTyping,
  },
  props: {
    selecionadoRetornoLista: { type: [Object, Array], default: Object },
    fornecedoresExistentesIds: { type: Array, default: Array },
  },
  data() {
    return {
      fornecedoresDados: [],
      fornecedorId: '',
      listaSelecionados: [],
      loadingFornecedor: false,
      nomeFornecedor: null,
    };
  },

  mounted() {
    // this.getFornecedores();
  },
  watch: {
    selecionadoRetornoLista(item) {
      this.listaSelecionados = this.listaSelecionados.filter(
        (x) => x !== item.id
      );
      this.getFornecedores(this.nomeFornecedor);
    },

    fornecedoresExistentesIds(fornecedoresExistentesIds) {
      this.listaSelecionados = fornecedoresExistentesIds;
    },
  },
  methods: {
    selecionarFornecedor() {
      this.listaSelecionados.push(this.fornecedorId);
      this.$emit('emitir-selecionado', this.fornecedorId);
      this.fornecedorId = '';

      this.getFornecedores(this.nomeFornecedor);
    },

    filtrarFornecedoresSelecionados(data, selecionados) {
      return data.filter((x) => !selecionados.some((id) => x.id == id));
    },

    getFornecedores(nome) {
      this.nomeFornecedor = nome;
      this.loadingFornecedor = true;
      EmpresaService.obterFornecedoresPorNome(nome)
        .then((data) => {
          data.itens = this.filtrarFornecedoresSelecionados(
            data.itens,
            this.listaSelecionados
          );

          // data = data.map((row) => ({
          //   ...row,
          //   text: row.razaoSocial,
          //   value: row.id,
          // }));
          this.fornecedoresDados = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingFornecedor = false;
        });
    },
  },
};
</script>
