<template>
  <div>
    <titulo-produtos @cadastrar="openModal" />
    <filtro-produtos @filtro="setFiltro" @filtrar="getProdutos" />
    <tabela-produto
      :items="produtos"
      @editar="openModal"
      @atualiza-tabela="getProdutos"
    />
    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getProdutos"
    />
    <modal-produto
      :exibir="modais.produto"
      :dados="modais.produtoDados"
      @fechar="closeModal"
      @atualiza-tabela="getProdutos"
    />
  </div>
</template>
<script>
// Utils & Aux:
import breadcrumb from '@/common/utils/breadcrumb';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import modais from '@/common/utils/modais';

// Services:
import ProdutosService from '@/common/services/produtos/produtos.service.js';

// Components:
import ModalProduto from './components/modal/Index';
import TabelaProduto from './components/Tabela';
import TituloProdutos from '@/views/suprimentos/produtos/components/TituloProdutos';
import Paginacao from '@/components/paginacao/Paginacao';
import FiltroProdutos from './components/Filtro';
export default {
  components: {
    TabelaProduto,
    ModalProduto,
    TituloProdutos,
    FiltroProdutos,
    Paginacao,
  },
  data() {
    return {
      produtos: [],
      modais: {
        produto: false,
        produtoDados: {},
      },
      filtro: {
        descricao: '',
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: 'SUPRIMENTOS.SUPRIMENTOS' },
      { titulo: 'SUPRIMENTOS.PRODUTOS' },
    ]);
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getProdutos() {
      this.$store.dispatch(START_LOADING);
      ProdutosService.obterPorExpressaoPaginado(
        this.paginacao,
        this.filtro.descricao
      )
        .then(({ data }) => {
          this.produtos = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    setFiltro(value) {
      this.filtro = value;
    },
    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
