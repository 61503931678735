<template>
  <div>
    <b-table
      :items="tabela.items"
      :fields="tabela.fields"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(acoes)="item">
        <b-button variant="danger" @click="remover(item.item)">
          <font-awesome-icon icon="fa-solid fa-trash" />
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    listaFornecedores: { type: Array, default: Array },
  },
  data() {
    return {
      tabela: {
        items: [],
        fields: [
          {
            key: 'cnpj',
            label: this.$t('SUPRIMENTOS.CNPJ'),
            sortable: true,
          },
          {
            key: 'razaoSocial',
            label: this.$t('SUPRIMENTOS.RAZAO_SOCIAL'),
            sortable: true,
          },
          {
            key: 'nomeFantasia',
            label: this.$t('SUPRIMENTOS.NOME_FANTASIA'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('SUPRIMENTOS.ACOES'),
            sortable: true,
          },
        ],
      },
    };
  },
  watch: {
    listaFornecedores() {
      this.tabela.items = this.listaFornecedores;
    },
  },
  methods: {
    remover(item) {
      this.$emit('remover-fornecedor', item);
    },
  },
};
</script>
