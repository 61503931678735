import ApiService from '@/common/api/api.service';

let apiBasePath = 'Produto';

const ProdutosService = {
  // FUNÇÕES CRUD:
  async criar(form) {
    let { data } = await ApiService.post(`${apiBasePath}`, form);
    return data;
  },
  async editar(form) {
    let { data } = await ApiService.put(`${apiBasePath}`, form);
    return data;
  },
  async excluir(itemId) {
    let { data } = await ApiService.delete(`${apiBasePath}/${itemId}`);
    return data;
  },
  // Fornecedores:
  async adicionarFornecedor(form) {
    let { data } = await ApiService.put(
      `${apiBasePath}/fornecedor/adicionar`,
      form
    );
    return data;
  },
  async removerFornecedor(form) {
    let { data } = await ApiService.delete(
      `${apiBasePath}/fornecedor/remover?FornecedorId=${form.fornecedorId}&ProdutoId=${form.produtoId}`
    );
    return data;
  },
  // FUNÇÕES DE OBTENÇÃO DE DADOS:
  async obterProduto(itemId) {
    let { data } = await ApiService.get(`${apiBasePath}/${itemId}`);
    return data;
  },
  async obterTodos(form) {
    let { data } = await ApiService.get(`${apiBasePath}`, form);
    return data;
  },
  async obterPorExpressaoPaginado(paginacao, expressao) {
    let { data } = await ApiService.get(`${apiBasePath}/listar-por-paginacao`, {
      params: {
        ...paginacao,
        expressao,
      },
    });
    return data;
  },
};

export default ProdutosService;
